import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { miscLinks } from '../../utils/internalLinks';

const LinkFooterListItem = ({ link }) => {
  let ele = link.isExternal ? (
    <a href={link.link} rel="noopener noreferrer" target="_blank">
      {link.title}
    </a>
  ) : (
    <Link to={link.link}>{link.title}</Link>
  );
  return <li>{ele}</li>;
};

const LinkFooterList = ({ links }) => {
  const linkItems = links.map((link) => (
    <LinkFooterListItem link={link} key={`footer-link-${link.title}`} />
  ));
  return <ul>{linkItems}</ul>;
};

class LinkFooter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        // {
        //   title: 'Blogs',
        //   link: miscLinks.blog,
        //   isExternal: true,
        // },
        {
          title: 'Join us',
          link: miscLinks.career,
        },
      ],
      bottomLinks: [
        {
          title: 'Terms & Conditions',
          link: miscLinks.terms,
        },
        {
          title: 'Privacy Policy',
          link: miscLinks.privacy,
        },
      ],
    };
  }

  render() {
    return (
      <div className="links-footer">
        <LinkFooterList links={this.state.links} />
        <LinkFooterList links={this.state.bottomLinks} />
        <p>© TagTaste Foods Pvt. Ltd. | {new Date().getFullYear()}</p>
      </div>
    );
  }
}

LinkFooterListItem.propTypes = {
  link: PropTypes.object,
};
LinkFooterList.propTypes = {
  links: PropTypes.array,
};

export default LinkFooter;
