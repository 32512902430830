import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { collaborationInternalLinks } from "../../../utils/internalLinks";
import { typesOfCollaborations } from "../../../utils/collaborationHelper";
import CloseIcon from "../../../assets/images/common/modals/close.svg";
import PRCollabIcon from "../../../assets/images/collaborations/form/pr-create.svg";
import AuditCollabIcon from "../../../assets/images/collaborations/form/audit.svg";
import MandatoryInformationModal from "../MandatoryInformationModal";
import ScrollableModal from "../common/ScrollableModal";

const texts = {
  title: `Select Review Type`,
};
const types = [
  {
    title: "Product Review",
    description:
      "Get feedback on your products from some real customers and experts.",
    link: collaborationInternalLinks.create(
      typesOfCollaborations.productReview
    ),
    icon: PRCollabIcon,
  },
  {
    title: "Product Consistency Tracking",
    description: "Check the consistency across multiple batches of a product.",
    link: collaborationInternalLinks.create(
      `${typesOfCollaborations.productReview}?track_consistency=true`
    ),
    icon: AuditCollabIcon,
  },
];

function ReviewCreate(props) {
  const { handleClose, history, user: { profile, verified_at } } = props;
  const [mandatoryFieldModalVisible, setMandatoryFieldModalVisible] =
    useState(false);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [creationType, setCreationType] = useState("");

  useEffect(() => {
    handleCheckFields();
  }, []);

  const handleCheckFields = () => {
    setMandatoryFields(prevState => {
      let val = [...prevState];
      if (typeof verified_at === "object")
        val.push("verified_email") // val = [...val, "verified_email"]
      if (!profile.verified_phone)
        val.push("verified_phone") // val = [...val, "verified_phone"]
      return val;
    })
  };
  const closeMandatoryModal = () => {
    setMandatoryFieldModalVisible(false);
  };

  return (
    <>
      <ScrollableModal
        showHeader
        title={texts.title}
        handleClose={handleClose}
        showFooter={false}
        containerClassName="premium-modal"
        isModalOpen
        paddingLessContent={false}
      >
        <div className="default-modal__content">
          {types && (
            <ul className="premium-modal__list">
              {types.map((type) => {
                return (
                  <li
                    className="premium-modal__list__item__container"
                    key={`type-${type.title}`}
                  >
                    <button
                      className="premium-modal__list__item"
                      onClick={() => {
                        if (mandatoryFields.length >= 1) {
                          setMandatoryFieldModalVisible(true);
                          setCreationType(type.link);
                        } else {
                          history.push(type.link);
                        }
                      }}
                    >
                      <div className="premium-modal__list__icon__container">
                        <img
                          className="premium-modal__list__icon"
                          src={type.icon}
                          alt={type.icon}
                        />
                      </div>
                      <div className="premium-modal__list__info">
                        <p className="premium-modal__list__info__title">
                          {type.title}
                        </p>
                        <p className="premium-modal__list__info__helper">
                          {type.description}
                        </p>
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </ScrollableModal>
      {mandatoryFieldModalVisible && (
        <MandatoryInformationModal
          useFor="creation"
          mandatoryFields={mandatoryFields}
          closeModal={closeMandatoryModal}
          proceedAction={() => {
            setMandatoryFieldModalVisible(false);
            history.push(creationType);
          }}
          profile={profile}
        />
      )}
    </>
  );
}

ReviewCreate.propTypes = {
  handleClose: PropTypes.func,
  history: PropTypes.object,
};

export default ReviewCreate;
