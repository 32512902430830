import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "./containers/loggedOut/Login";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import Careers from "./containers/loggedOut/Careers";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import Cookies from 'universal-cookie';
import NotificationSocket from "./containers/loggedIn/Notifications/NotificationSocket";
import {
  onboardingLink,
  miscLinks,
  ttfbLinks,
  surveyLinks,
  quizLinks,
  profileLinks,
  workshopLinks,
} from "./utils/internalLinks";
import "./App.scss";
import { getShortProfileCurrentUser, getCurrentUser } from "./actions/user";
import { checkForBypassingOnboardingCheck } from "./utils/profileHelper";
import { logoutUserLocally } from "./actions/login/logout";
import "intersection-observer"; // adding polyfill
import RedirectionRoute from "./containers/app/RedirectionRoute";
import PublicPages from "./containers/app/PublicPage";
import OnlyLoggedOut from "./containers/app/OnlyLoggedOut";
import PrivateRoute from "./containers/app/PrivateRoute";
import LandingPage from "./containers/loggedIn/LandingPage";
import { collaborationInternalLinks } from "./utils/internalLinks";
import { logoutInterceptor } from "./utils/helper";
import BlockAccount from "./containers/loggedIn/BlockedAccount/block-accounts";
import axios from "axios";
import { addSessionInterceptor, handleSession, resetSession } from "./utils/sessionHelper.js";
import withPromptStructure from "./HOC/withPrompts.js";
import SuccessDetailsComponent from "./containers/loggedIn/SuccessStoryDetails.js";

const ChatComponent = loadable(
  () => import("./containers/loggedIn/Chat/ChatApp"),
  {
    fallback: <div>Loading...</div>,
  }
);
const Feed = loadable(() => import("./containers/loggedIn/Feed/Feed"));
const HashtagFeed = loadable(() =>
  import("./containers/loggedIn/HashTag/Feed")
);
const TrendingHashtagFeed = loadable(() =>
  import("./containers/loggedIn/HashTag/TrendingFeed")
);
const CollaborationView = loadable(() =>
  import("./containers/loggedIn/Collaborations/View")
);
const CollaborationsSeeAll = loadable(() =>
  import("./containers/loggedIn/Collaborations/All")
);
const CollaborationManage = loadable(() =>
  import("./containers/loggedIn/Collaborations/CollaborationManage")
);
const CollaborationCreateOrUpdate = loadable(() =>
  import("./containers/loggedIn/Collaborations/CreateOrUpdate")
);
// Questionnaire Public Preview
const QuestionnaireComponent = loadable(() =>
  import("./containers/loggedIn/Collaborations/Questionnaire/Questionnaire.js"));

// Private Review
const ProductReviewManage = loadable(() =>
  import("./containers/loggedIn/Collaborations/ProductReview/Manage/Dashboard")
);
const ProductReviewReports = loadable(() =>
  import("./containers/loggedIn/Collaborations/ProductReview/Manage/Reports")
);
const ProductReviewView = loadable(() =>
  import("./containers/loggedIn/Collaborations/ProductReview/ProductReviewView")
);
const ProductReviewCreateOrUpdateComponent = loadable(() =>
  import("./containers/loggedIn/Collaborations/ProductReview/CreateOrUpdate")
);
// Private Review
const SharedDetailsPage = loadable(() =>
  import("./containers/loggedIn/DetailPages/SharedDetails")
);
const PostDetails = loadable(() =>
  import("./containers/loggedIn/DetailPages/PostDetails")
);
const PhotoDetails = loadable(() =>
  import("./containers/loggedIn/DetailPages/PhotoDetails")
);
const FeedCardDetails = loadable(() =>
  import("./containers/loggedIn/DetailPages/FeedCard")
);
const Polls = loadable(() => import("./containers/loggedIn/Polls"));
const PollDetails = loadable(() =>
  import("./containers/loggedIn/DetailPages/PollingDetails")
);
const Notifications = loadable(() =>
  import("./containers/loggedIn/Notifications/Notifications")
);
const ForgotPassword = loadable(() =>
  import("./containers/loggedOut/ForgotPassword/ForgotPassword")
);
const ResetPassword = loadable(() =>
  import("./containers/loggedOut/ForgotPassword/ResetPassword")
);
const CreatePassword = loadable(() =>
  import("./containers/loggedOut/ForgotPassword/CreatePassword")
);
const LoggedOutHome = loadable(() =>
  import("./containers/loggedOut/LoggedOutHome")
);
const Register = loadable(() => import("./containers/loggedOut/Register"));
const FollowersComponent = loadable(() =>
  import("./containers/loggedIn/Profile/Followers")
);
const FollowingComponent = loadable(() =>
  import("./containers/loggedIn/Profile/Following")
);
const PhotoComponent = loadable(() =>
  import("./containers/loggedIn/Profile/Photos")
);
const CompanyPhotoComponent = loadable(() =>
  import("./containers/loggedIn/Company/Photos")
);
const MoreBadgeComponent = loadable(() =>
  import("./containers/loggedIn/Profile/MoreBadges")
);
const SettingsComponent = loadable(() =>
  import("./containers/loggedIn/Settings/All")
);
const AccountManagementComponent = loadable(() =>
  import("./containers/loggedIn/AccountManagement/All")
);
const OnboardingApp = loadable(() =>
  import("./containers/loggedIn/Onboarding/OnboardingApp")
);
const MyPassbook = loadable(() =>
  import("./containers/loggedIn/Profile/MyPassbook")
);
const PaidTasterProgram = loadable(() =>
  import("./containers/loggedIn/Profile/MyPassbook/PaidTasterProgram")
);
const ProfileView = loadable(() =>
  import("./containers/loggedIn/Profile/View")
);
const PeopleSeeAll = loadable(() =>
  import("./containers/loggedIn/Profile/All")
);
const CompanyView = loadable(() =>
  import("./containers/loggedIn/Company/View")
);
const CompanySeeAll = loadable(() =>
  import("./containers/loggedIn/Company/All")
);
const TastingComponent = loadable(() =>
  import("./containers/loggedIn/Collaborations/ProductReview/Tasting/Tasting")
);
const ProductView = loadable(() =>
  import("./containers/loggedIn/Products/ProductView")
);
const AllProducts = loadable(() =>
  import("./containers/loggedIn/Products/All")
);
const AllReviews = loadable(() => import("./containers/loggedIn/Reviews/All"));
const ProductReviews = loadable(() =>
  import("./containers/loggedIn/ProductReviews")
);
const MyCollaborations = loadable(() =>
  import("./containers/loggedIn/Profile/MyCollaborations")
);
const MySurvey = loadable(() =>
  import("./containers/loggedIn/Profile/MySurvey")
);
const MyQuiz = loadable(() => import("./containers/loggedIn/Profile/MyQuiz"));
const MyInvitations = loadable(() => import("./containers/loggedIn/Profile/MyInvitations"));
const MyWorkshop = loadable(() => import("./containers/loggedIn/Profile/MyWorkshop"));
const ReviewCollection = loadable(() =>
  import("./containers/loggedIn/Reviews/Collections")
);
// Explore pages
const Explore = loadable(() => import("./containers/loggedIn/Explore/Index"));
// Public Views
const ProfilePublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/ProfileView")
);
const PostPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/PostView")
);
const SharedPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/SharedView")
);
const PhotoPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/PhotoView")
);
const ProductPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/ProductView")
);
const PollPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/PollView")
);
const FeedCardPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/FeedCard")
);
const CompanyPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/CompanyView")
);
const CollaborationPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/CollaborationView")
);
const ProductReviewPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/ProductReviewView")
);
const Privacy = loadable(() => import("./containers/loggedIn/Misc/Privacy"));
const Terms = loadable(() => import("./containers/loggedIn/Misc/Terms"));
const CommunityGuidelines = loadable(() =>
  import("./containers/loggedIn/Misc/CommunityGuidelines")
);
const FAQS = loadable(() => import("./containers/loggedIn/Misc/FAQs"));
const NotFoundContainer = loadable(() => import("./components/misc/404"));
const EmailVerify = loadable(() => import("./components/misc/EmailVerify"));
const Services = loadable(() => import('./containers/loggedIn/Misc/Services.js'));
const AboutUs = loadable(() => import('./containers/loggedIn/Misc/AboutUs'));

// Survey Pages
const SurveyCreateOrUpdate = loadable(() =>
  import("./containers/loggedIn/Survey/CreateOrUpdate")
);
const SurveyReports = loadable(() =>
  import("./containers/loggedIn/Survey/Reports")
);
const SurveyManage = loadable(() =>
  import("./containers/loggedIn/Survey/Manage")
);
const SurveyDetails = loadable(() =>
  import("./containers/loggedIn/Survey/View")
);
const SurveySeeAll = loadable(() => import("./containers/loggedIn/Survey/All"));
const SurveyFill = loadable(() => import("./containers/loggedIn/Survey/Fill"));
const SurveyPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/SurveyView")
);

//Quiz Pages
const QuizCreateOrUpdate = loadable(() =>
  import("./containers/loggedIn/Quiz/CreateOrUpdate")
);
const QuizDetails = loadable(() => import("./containers/loggedIn/Quiz/View"));
const QuizPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/QuizView")
);
const QuizPlay = loadable(() => import("./containers/loggedIn/Quiz/Play"));
const QuizSeeAll = loadable(() => import("./containers/loggedIn/Quiz/All"));
const QuizReports = loadable(() =>
  import("./containers/loggedIn/Quiz/Reports")
);
// Workshop Pages 
const WorkshopSeeAll = loadable(() => import("./containers/loggedIn/Workshop/All"));
const WorkshopDetails = loadable(() => import("./containers/loggedIn/Workshop/View"));
const WorkshopPublicView = loadable(() =>
  import("./containers/loggedOut/PublicPages/WorkshopView")
);

// Business Pages
const BusinessLanding = loadable(() =>
  import("./containers/loggedIn/Business/Landing")
);
const SingleProduct = loadable(() =>
  import("./containers/loggedIn/Business/SingleProduct")
);
const BusinessAllProducts = loadable(() =>
  import("./containers/loggedIn/Business/AllProducts")
);
const BusinessPartners = loadable(() =>
  import("./containers/loggedIn/Business/Partners")
);
const BusinessManufacturers = loadable(() =>
  import("./containers/loggedIn/Business/Manufacturers")
);
const BusinessDistributors = loadable(() =>
  import("./containers/loggedIn/Business/Distributors")
);
const TrackConsistency = loadable(() =>
  import("./containers/loggedIn/Business/TrackConsistency")
);
const ConsistencyMembers = loadable(() =>
  import("./containers/loggedIn/Business/Consistency/Members")
);
const ConsistencyAllProducts = loadable(() =>
  import("./containers/loggedIn/Business/Consistency/AllProducts")
);
const ConsistencyClosedProducts = loadable(() =>
  import("./containers/loggedIn/Business/Consistency/ClosedProducts")
);
const TrackConsistencyProduct = loadable(() =>
  import("./containers/loggedIn/Business/TrackConsistencyProduct")
);
const ConsistencyProductReport = loadable(() =>
  import("./containers/loggedIn/Business/Consistency/Reports")
);
// Report Email content
const ReportEmail = loadable(() =>
  import("./containers/loggedOut/PublicPages/reportPages/ReportEmail")
);
const NutritiveValues = loadable(() =>
  import("./containers/loggedOut/PublicPages/reportPages/NutritiveValues")
);
// const NutritiveValues = loadable(() =>
//   import("./containers/loggedOut/PublicPages/reportPages/NutritiveValues")
// );

ReactGA.initialize(process.env.REACT_APP_GA4_KEY);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_NODE_ENV,
  tracesSampleRate: 0.7,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasCheckedForUser: false,
    };
    this.handleLocalStorageChanges = this.handleLocalStorageChanges.bind(this);
    this.loadGooglePlacesApi = this.loadGooglePlacesApi.bind(this);
  }

  handleLocalStorageChanges() {
    const { login } = this.props,
      originalToken = login.token;
    window.addEventListener("focus", () => {
      let currentToken = window.localStorage.getItem("authToken");
      if (originalToken !== currentToken) {
        window.location.reload();
      }
    });
  }

  loadGooglePlacesApi() {
    // load places api
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}&libraries=places&types=(cities)`;
    script.async = true;
    script.defer = true;
    script.type = "text/javascript";
    script.onerror = function () {
      console.error('Failed to load Google Places API script.');
    };
    script.onload = function () {
      window.googlePlacesLoaded = true;
    };
    document.body.appendChild(script);
  }

  removeAxiosInterceptor() {
    // Clean up interceptor
    axios.interceptors.response.eject(this.axiosInterceptor);
    resetSession();
  }

  componentDidMount() {
    window.addEventListener("storage", this.handleLocalStorageChanges);
    this.loadGooglePlacesApi();
    const { login } = this.props;
    if (login && login.loggedIn) {
      this.getUser();
    } else {
      this.setState({
        hasCheckedForUser: true,
      });
    }
    /* window.addEventListener('beforeunload', () => {
      const cookies = new Cookies();
      cookies.remove('passwordVerified');
    }); */
  }

  addSessionInterceptor = () => {
    axios.interceptors.response.use(
      response => {
        handleSession();
        return response;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  getUser() {
    const {
      login,
      logoutUserLocally,
      getShortProfileCurrentUser,
      getCurrentUser,
    } = this.props;
    logoutInterceptor(logoutUserLocally);
    getShortProfileCurrentUser(login.token).then(async (val) => {
      if (
        val &&
        val.profile &&
        window.location.pathname.indexOf(`onboarding`) === -1 &&
        !checkForBypassingOnboardingCheck() &&
        !val.profile.onboarding_complete
      ) {
        window.location.href = onboardingLink;
      }

      if (val && val.profile) {
        await getCurrentUser(login.token);
        this.setState({
          hasCheckedForUser: true,
        });
        this.addSessionInterceptor();
      } else {
        logoutUserLocally();
        window.location.pathame = miscLinks.home;
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.login.loggedIn &&
      prevProps.login.loggedIn !== this.props.login.loggedIn
    ) {
      this.setState({
        hasCheckedForUser: true,
      });
    } else if (
      !prevProps.login.loggedIn &&
      prevProps.login.loggedIn !== this.props.login.loggedIn
    ) {
      this.getUser();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleLocalStorageChanges);
    this.removeAxiosInterceptor(this.addSessionInterceptor)
  }

  render() {
    if (!this.state.hasCheckedForUser) return null;
    return (
      <>
        <Router>
          <div className="App">
            <Switch>
              <OnlyLoggedOut
                exact
                path="/"
                showMeta={true}
                component={LoggedOutHome}
              />
              <OnlyLoggedOut exact path="/login" component={Login} />
              <OnlyLoggedOut exact path="/register" component={Register} />
              <OnlyLoggedOut
                exact
                path="/password/forgot"
                component={ForgotPassword}
              />
              <OnlyLoggedOut
                exact
                path="/password/reset/:code"
                component={ResetPassword}
              />
              <OnlyLoggedOut
                exact
                path="/password/create/:code"
                component={CreatePassword}
              />
              <PublicPages
                exact
                path="/preview/questionnaire/:id"
                component={(params) => <QuestionnaireComponent {...params} />}
                publicComponent={(params) => <QuestionnaireComponent {...params} />}
                isPreview
              />
              <PublicPages
                exact
                path="/preview/questionnaire/:id/view"
                component={(params) => <TastingComponent {...params} isPreview />}
                publicComponent={(params) => <TastingComponent {...params} isPreview />}
                isPreview
              />
              <PublicPages
                path="/career/:type/:url"
                component={Careers}
                publicComponent={Careers}
              />
              <PublicPages
                exact
                path="/career"
                component={Careers}
                publicComponent={Careers}
              />
              <PrivateRoute
                path="/onboarding"
                hideTopBar={true}
                component={OnboardingApp}
              />
              {/* Top Bar Routes */}
              <PublicPages
                exact
                path={miscLinks.services}
                withFooter={true}
                component={Services}
                publicComponent={Services}
              /> 
              <PublicPages
                exact
                path={miscLinks.about}
                withFooter={true}
                component={AboutUs}
                publicComponent={AboutUs}
              />
              {/* <PublicPages  // success story is commented by product team
                exact
                path="/success-stories/"
                component={SuccessDetailsComponent}
                publicComponent={SuccessDetailsComponent}
              />
              <PublicPages  / success story is commented by product team
                exact
                path="/success-stories/:id"
                component={SuccessDetailsComponent}
                publicComponent={SuccessDetailsComponent}
              /> */}
              {/** Collaboration routes */}
              <PrivateRoute
                exact
                path="/collaborations/create"
                component={CollaborationCreateOrUpdate}
              />
              <PrivateRoute
                exact
                path="/collaborations/create/product-review"
                component={ProductReviewCreateOrUpdateComponent}
              />
              <PrivateRoute
                exact
                path="/collaborations/:id/product-review/edit"
                component={ProductReviewCreateOrUpdateComponent}
              />
              {/* <PublicPages exact path="/collaborations/:id/product-review" withFooter={true} disableTracker={true} component={ProductReviewView} publicComponent={ProductReviewPublicView} /> */}
              <PublicPages
                exact
                path="/collaborations/:id/product-review"
                withFooter={true}
                component={withPromptStructure(ProductReviewView)}
                publicComponent={ProductReviewPublicView}
              />
              {/* start - collab manage internal routs */}
              <PrivateRoute // applicants 
                exact
                path="/collaborations/:id/product-review/manage/:viewType/:productId"
                component={ProductReviewManage}
              />

              <PrivateRoute // applicants 
                exact
                path="/collaborations/:id/product-review/manage/:viewType"
                component={ProductReviewManage}
              />
              <PrivateRoute // applicants 
                exact
                path="/collaborations/:id/product-review/manage"
                component={ProductReviewManage}
              />
              {/* end - collab manage internal routs */}
              <PrivateRoute
                exact
                path="/collaborations/:id/product-review/reports"
                component={ProductReviewReports}
              />
              <PrivateRoute
                exact
                path="/collaborations/:id/product-review/reports/downloads"
                component={ProductReviewReports}
              />
              <PrivateRoute
                exact
                path="/collaborations/:id/product-review/reports/graphs"
                component={ProductReviewReports}
              />
              <PrivateRoute
                exact
                path="/collaborations/:id/manage"
                component={CollaborationManage}
              />
              <RedirectionRoute
                exact
                from="/collaborate/:id/applications"
                to="/collaborations/:id/manage"
              />
              <PrivateRoute
                exact
                path="/collaborations/:id/edit"
                component={CollaborationCreateOrUpdate}
              />
              <PublicPages
                exact
                path="/collaborations/:id"
                withFooter={true}
                component={withPromptStructure(CollaborationView)}
                publicComponent={CollaborationPublicView}
              />
              <PrivateRoute
                exact
                path="/collaborations"
                withFooter={true}
                component={CollaborationsSeeAll}
              />
              {/* Survey routes */}
              <PrivateRoute
                exact
                path={surveyLinks.create}
                component={SurveyCreateOrUpdate}
              />
              <PrivateRoute
                exact
                path="/surveys/:id/reports"
                component={SurveyReports}
              />
              <PrivateRoute
                exact
                path="/surveys/:id/manage"
                component={SurveyManage}
              />
              <PrivateRoute
                exact
                path="/surveys/:id/fill"
                component={SurveyFill}
              />
              <PublicPages
                exact
                path="/surveys/:id/preview"
                component={(params) => <SurveyFill {...params} />}
                publicComponent={(params) => <SurveyFill {...params} />}
              />
              <PrivateRoute
                exact
                path="/surveys/:id/edit"
                component={SurveyCreateOrUpdate}
              />
              <PublicPages
                exact
                path="/surveys/:id"
                component={withPromptStructure(SurveyDetails)}
                publicComponent={SurveyPublicView}
              />
              <PublicPages
                exact
                path="/preview/survey/:id"
                component={(params) => <SurveyDetails {...params} isPreview />}
                publicComponent={(params) => <SurveyPublicView {...params} isPreview />}
              />
              <PrivateRoute
                exact
                path={surveyLinks.all}
                component={SurveySeeAll}
              />

              {/* Quiz routes */}

              <PrivateRoute
                exact
                path={quizLinks.create}
                component={QuizCreateOrUpdate}
              />
              <PublicPages
                exact
                path="/quiz/:id"
                component={withPromptStructure(QuizDetails)}
                publicComponent={QuizPublicView}
              />
              <PrivateRoute
                exact
                path="/quiz/:id/edit"
                component={QuizCreateOrUpdate}
              />
              <PrivateRoute exact path="/quiz/:id/play" component={withPromptStructure(QuizPlay)} />
              <PrivateRoute exact path={quizLinks.all} component={QuizSeeAll} />
              <PrivateRoute
                exact
                path="/quiz/:id/reports"
                component={QuizReports}
              />
              {/* Workshop routes */}
              <PrivateRoute exact path={workshopLinks.all} component={WorkshopSeeAll} />
              <PublicPages
                exact
                path="/workshop/:id"
                component={WorkshopDetails}
                publicComponent={WorkshopPublicView}
              />
              {/* Explore route */}
              <PrivateRoute
                exact
                path="/explore"
                withFooter={true}
                component={Explore}
              />
              {/* Product pages */}
              <PublicPages
                exact
                path="/reviews/products/:id"
                component={withPromptStructure(ProductView)}
                publicComponent={ProductPublicView}
              />
              <PrivateRoute
                exact
                path="/reviews/products"
                withFooter={true}
                component={AllProducts}
              />
              <PrivateRoute
                exact
                path="/reviews"
                withFooter={true}
                component={AllReviews}
              />
              <PrivateRoute
                exact
                path={collaborationInternalLinks.productReviews}
                withFooter={true}
                component={ProductReviews}
              />
              <PrivateRoute
                exact
                path="/collection/:id"
                component={ReviewCollection}
              />
              {/* Notifications route */}
              <PrivateRoute
                exact
                path="/notifications"
                withFooter={true}
                component={Notifications}
              />
              {/* Settings route */}
              <PrivateRoute
                exact
                path="/settings"
                withFooter={true}
                component={SettingsComponent}
              />

              <PrivateRoute
                exact
                path="/block-accounts"
                withFooter={true}
                component={BlockAccount}
              />
              {/* Profile Delete route */}
              <PublicPages
                exact
                path="/accountmanagement/:type"
                // withFooter={true}
                component={AccountManagementComponent}
                publicComponent={LoggedOutHome}
              />
              {/* Poll routes */}
              <PrivateRoute
                exact
                path="/polls"
                withFooter={true}
                component={withPromptStructure(Polls)}
              />
              <PublicPages
                exact
                path="/polling/:id"
                component={withPromptStructure(PollDetails)}
                publicComponent={PollPublicView}
              />
              {/** Post / Shoutout routes */}
              <PublicPages
                exact
                path="/shoutout/:id"
                component={withPromptStructure(PostDetails)}
                publicComponent={PostPublicView}
              />
              {/* <PublicPages exact path="/shared/:id/:type/:postid" component={PostDetails} publicComponent={PostPublicView} /> */}
              <PublicPages
                exact
                path="/photo/:id"
                component={withPromptStructure(PhotoDetails)}
                publicComponent={PhotoPublicView}
              />
              {/* Feed card */}
              <PublicPages
                exact
                path="/feed/card/:id"
                component={FeedCardDetails}
                publicComponent={FeedCardPublicView}
              />
              {/** Shared Post detail page */}
              {/* <PrivateRoute exact path="/shared/:id/:type/:postid" component={SharedDetailsPage} /> */}
              <PublicPages
                exact
                path="/shared/:id/:type/:postid"
                component={SharedDetailsPage}
                publicComponent={SharedPublicView}
              />
              <RedirectionRoute
                exact
                from="/feed/view/share/:postType/:shareId/:postId"
                to="/shared/:shareId/:postType/:postId"
              />
              <PrivateRoute
                exact
                path="/feed/hashtag/:hashtag"
                component={HashtagFeed}
              />
              <PrivateRoute
                exact
                path="/feed/hashtag"
                component={HashtagFeed}
              />
              <PrivateRoute
                exact
                path="/trending/hashtag"
                component={TrendingHashtagFeed}
              />
              <PrivateRoute exact path="/feed" component={withPromptStructure(Feed)} />
              <PrivateRoute exact path="/home" component={withPromptStructure(LandingPage)} />
              <PrivateRoute
                exact
                path="/onboarding"
                component={OnboardingApp}
              />
              <PrivateRoute exact path="/messenger" component={ChatComponent} />
              {/* Profile pages */}
              <PrivateRoute exact path="/passbook" component={MyPassbook} />
              <PrivateRoute
                exact
                path="/paid-taster-program"
                component={PaidTasterProgram}
              />
              <PrivateRoute exact path="/people" component={PeopleSeeAll} />
              <PublicPages
                exact
                path={["/@:handle", "/profile/:currentProfileId"]}
                component={ProfileView}
                publicComponent={ProfilePublicView}
              />
              <PrivateRoute
                exact
                path="/my-collaborations"
                component={MyCollaborations}
              />
              <PrivateRoute
                exact
                path="/my-tastings"
                component={(params) => <MyCollaborations {...params} isProductReviewList />}
              />
              <PrivateRoute
                exact
                path={profileLinks.mySurvey}
                component={MySurvey}
              />
              <PrivateRoute
                exact
                path={profileLinks.myQuiz}
                component={MyQuiz}
              />
              <PrivateRoute
                exact
                path={profileLinks.myInvitation}
                component={MyInvitations}
              />
              <PrivateRoute
                exact
                path={profileLinks.myWorkshop}
                component={MyWorkshop}
              />
              {/* Company pages */}
              <PublicPages
                exact
                path="/companies/:id"
                component={CompanyView}
                publicComponent={CompanyPublicView}
              />
              <PrivateRoute
                exact
                path="/companies"
                component={CompanySeeAll}
                publicComponent={CompanySeeAll}
              />
              {/* Follower Following pages */}
              <PrivateRoute
                exact
                path="/profile/:id/followers"
                component={FollowersComponent}
              />
              <PrivateRoute
                exact
                path="/profile/:id/following"
                component={FollowingComponent}
              />
              <PrivateRoute
                exact
                path={["/@:handle/photos", "/profile/:currentProfileId/photos"]}
                component={PhotoComponent}
              />
              <PrivateRoute
                exact
                path="/profile/:id/companies/:companyId/photos"
                component={CompanyPhotoComponent}
              />
              <PrivateRoute
                exact
                path="/profile/:id/companies/:companyId/followers"
                component={FollowersComponent}
              />
              <PrivateRoute
                exact
                path="/profile/:id/companies/:companyId/following"
                component={FollowingComponent}
              />
              <PrivateRoute
                exact
                path="/collaborations/:id/product-review/tasting/batch/:batchId"
                component={TastingComponent}
              />
              <PrivateRoute
                exact
                path="/reviews/products/:productId/review"
                component={TastingComponent}
              />
              <PrivateRoute
                exact
                path="/@:handle/badges"
                component={MoreBadgeComponent}
              />
              <PublicPages
                exact
                path="/privacy"
                component={Privacy}
                publicComponent={Privacy}
              />
              <PublicPages
                exact
                path="/terms"
                component={Terms}
                publicComponent={Terms}
              />
              <PublicPages
                exact
                path={miscLinks.communityGuidelines}
                component={CommunityGuidelines}
                publicComponent={CommunityGuidelines}
              />
              <PublicPages
                exact
                path="/faqs"
                component={FAQS}
                publicComponent={FAQS}
              />
              {/* Business Pages */}
              <PrivateRoute
                exact
                path="/business/:groupId/leads/partners"
                component={BusinessPartners}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/leads/manufacturers"
                component={BusinessManufacturers}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/leads/distributors"
                component={BusinessDistributors}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/leads/products"
                component={BusinessAllProducts}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/leads/products/:productId"
                component={SingleProduct}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/leads"
                component={BusinessLanding}
              />
              <PrivateRoute
                exact
                component={TastingComponent}
                path="/business/:groupId/consistency/products/:productId/batches/:batchId/review"
              />
              <PrivateRoute
                exact
                path="/business/:groupId/consistency"
                component={TrackConsistency}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/consistency/members"
                component={ConsistencyMembers}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/consistency/products"
                component={ConsistencyAllProducts}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/consistency/products/closed"
                component={ConsistencyClosedProducts}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/consistency/products/:productId"
                component={TrackConsistencyProduct}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/consistency/products/:productId/reports"
                component={ConsistencyProductReport}
              />
              <PrivateRoute
                exact
                path="/business/:groupId/consistency/products/:productId/reports/:batchId"
                component={ConsistencyProductReport}
              />
              {/* Report Pages */}
              <PublicPages
                exact
                path="/FutureOfFood"
                component={ReportEmail}
                publicComponent={ReportEmail}
              />
              <PublicPages
                exact
                path="/EnergyBarNutritionGuide"
                component={NutritiveValues}
                publicComponent={NutritiveValues}
              />
              {/* <PublicPages exact path="/about" component={About} publicComponent={About} /> */}
              <PublicPages
                path="/user/verify/email/:emailCode"
                component={EmailVerify}
                publicComponent={EmailVerify}
              />
              <PublicPages
                path="not-found"
                component={NotFoundContainer}
                sendMeta={true}
                publicComponent={NotFoundContainer}
              />
              <PublicPages
                path="*"
                component={NotFoundContainer}
                sendMeta={true}
                publicComponent={NotFoundContainer}
              />
            </Switch>
          </div>
        </Router>
        <NotificationSocket />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

const mapDispatchToProps = {
  getShortProfileCurrentUser,
  getCurrentUser,
  logoutUserLocally,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
