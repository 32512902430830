import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { getTTFBSidebar, setTTFBSidebarDropdown } from '../../actions/business/common';
import { hideInvitationDetailsModal, saveDonationModalData, setHideDonationModal, setShowDonationModal, toggleProfileCompletionModal, toggleProfileMenuModal } from '../../actions/common/modals';
import { resetComments } from '../../actions/feed/comments';
import { getFeed, resetFeed } from '../../actions/feed/feed';
import { getLandingPageData, getLandingTopBarData, resetLandingPage } from '../../actions/landingPage';
import { UpdateNotificationItem } from '../../actions/notifications';
import Logo from '../../assets/images/bar/logo.svg';
import HamburgerMenuIcon from '../../assets/images/bar/menu-options.svg';
import ProfileDownIcon from '../../assets/images/bar/profile-dropdown.svg';
import CloseIcon from '../../assets/images/common/modals/close.svg';
import ProfilePopUp from '../../components/ProfilePopUp';
import { screenBreakpoints } from '../../utils/commonVariables';
import { debounce } from '../../utils/helper';
import { exploreLink, feedLink, homeLink, miscLinks } from '../../utils/internalLinks';
import { BUSINESS_MODULES_SUPPORTED } from '../business/constants';
import DonationModal from '../modals/collaborations/DonationModal';
import CommonPortal from '../modals/common/CommonPortal';
import ProfileCommonModals from '../modals/common/ProfileCommonModals';
import PassbookModal from '../modals/passbook/index';
import ShowInvitationDetailsModal from '../modals/ShowInvitationDetailsModal';
import Avatar from '../user/Avatar';
import TopBar from './TopBar';


class LoggedInBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotificationBar: false,
      showProfileMenu: false,
      search: '',
      isExplorePage: false,
      isMobile: window.innerWidth <= screenBreakpoints.mob,
      showMobileNavMenu: false,
      showMobileAddMenu: false,
      businessDefaultLink: null,
      currentPage: props.location.pathname,
      active: false,
    };
    this._handleNotificationClick = this._handleNotificationClick.bind(this);
    this._handleProfileIconClick = this._handleProfileIconClick.bind(this);
    this._handlePopUpClose = this._handlePopUpClose.bind(this);
    this._handleResize = debounce(this._handleResize.bind(this));
    this._toggleMobileNavMenu = this._toggleMobileNavMenu.bind(this);
    this._getHomeIfHomePage = this._getHomeIfHomePage.bind(this);
    this._removeOverflowClass = this._removeOverflowClass.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleExplorePage = this._handleExplorePage.bind(this);
    this._handleBusinessLink = this._handleBusinessLink.bind(this);
    this.handleDonationModal = this.handleDonationModal.bind(this);
    this.handleUpdateList = this.handleUpdateList.bind(this);
    this.handleCloseInvitationModal = this.handleCloseInvitationModal.bind(this);
  }

  _handleNotificationClick() {
    const { showInvitationModal: { isVisible, data: { model } } } = this.props;
    if (!isVisible) {
      if (this.state.showNotificationBar) {
        document.removeEventListener('click', this._handleNotificationClick);
      } else {
        document.addEventListener('click', this._handleNotificationClick);
      }
      this.setState({
        showNotificationBar: !this.state.showNotificationBar,
      });
    }
  }


  _handlePopUpClose() {
    this.setState((prevState) => {
      return {
        ...prevState,
        showNotificationBar: false,
        showProfileMenu: false,
        showMobileNavMenu: false,
      };
    });
    const { profileModals, toggleProfileMenuModal } = this.props;
    if (profileModals.profileMenu.isVisible) {
      toggleProfileMenuModal();
    }
    if (profileModals.profileCompletion.isVisible) {
      toggleProfileCompletionModal();
    }
  }

  handleUpdateList(resData, invi_id) {
    const { showInvitationModal: { data: { notification_id } }, UpdateNotificationItem } = this.props;
    UpdateNotificationItem({ data: resData, id: notification_id });
  }

  handleCloseInvitationModal() {
    const { hideInvitationDetailsModal } = this.props;
    hideInvitationDetailsModal()
  }

  handleDonationModal(type) {
    const {
      showDonationModal: { isVisible },
      setHideDonationModal,
      setShowDonationModal
    } = this.props;
    if (isVisible) {
      setHideDonationModal(type);
    }
    else {
      setShowDonationModal(type);
    }
  }

  _handleProfileIconClick() {
    if (this.state.showProfileMenu) {
      document.removeEventListener('click', this._handleProfileIconClick);
    } else {
      document.addEventListener('click', this._handleProfileIconClick);
    }
    this.setState({
      showProfileMenu: !this.state.showProfileMenu,
    });
  }

  _removeOverflowClass() {
    this.setState({
      active: false
    });
  }

  _toggleMobileNavMenu() {
    this.setState({
      showMobileNavMenu: !this.state.showMobileNavMenu,
      active: !this.state.active
    });
  }

  _handleResize() {
    this.setState({
      isMobile: window.innerWidth <= screenBreakpoints.mob,
    });
  }

  _handleBusinessLink() {
    const {
      user: {
        user: { profile },
      },
      match: {
        params: { groupId: currentGroupId },
      },
      token,
      setTTFBSidebarDropdown,
      getTTFBSidebar,
    } = this.props;
    if (!profile || (profile && !profile.is_ttfb_user)) return;
    getTTFBSidebar(token).then((response) => {
      if (response && response.data && response.data.length) {
        let groupId = currentGroupId ? parseInt(currentGroupId) : response.data[0].id;
        let link =
          response.data[0].child &&
            response.data[0].child.length &&
            response.data[0].child[0].key &&
            response.data[0].child[0].child &&
            response.data[0].child[0].child.length &&
            response.data[0].child[0].child[0].key &&
            BUSINESS_MODULES_SUPPORTED[response.data[0].child[0].key] &&
            BUSINESS_MODULES_SUPPORTED[response.data[0].child[0].key][
            response.data[0].child[0].child[0].key
            ]
            ? BUSINESS_MODULES_SUPPORTED[response.data[0].child[0].key][
              response.data[0].child[0].child[0].key
            ].link(groupId)
            : null;
        if (link) {
          this.setState({
            businessDefaultLink: link,
          });
          setTTFBSidebarDropdown(groupId);
        }
      }
    });
  }

  componentDidMount() {
    this._handleExplorePage();
    this._handleBusinessLink();
    const { getLandingTopBarData, saveDonationModalData } = this.props;
    getLandingTopBarData(this.props.token);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._handleProfileIconClick);
    document.removeEventListener('click', this._handleNotificationClick);
    document.removeEventListener('click', this._toggleMobileNavMenu);
    this._handlePopUpClose();
    this._handleExplorePage();
  }

  _handleExplorePage() {
    const { location } = this.props;
    this.setState({
      isExplorePage: location.pathname.includes('explore'),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      document.removeEventListener('click', this._handleProfileIconClick);
      document.removeEventListener('click', this._handleNotificationClick);
      document.removeEventListener('click', this._toggleMobileNavMenu);
      document.querySelector('body').classList.remove('overflow-hidden');
      this._handlePopUpClose();
      this._handleExplorePage();
      this.setState({ currentPage: this.props.location.pathname });
    }
    if (
      this.props.user &&
      this.props.user.user &&
      this.props.user.user.profile &&
      (!prevProps.user ||
        (prevProps.user && prevProps.user.user && !prevProps.user.user.profile) ||
        (prevProps.user.user.profile &&
          this.props.user.user.profile.id !== prevProps.user.user.profile.id))
    ) {
      this._handleBusinessLink();
    }
  }

  _getHomeIfHomePage() {
    if (window.location.pathname === homeLink) {
      const { resetComments, token, resetLandingPage, getLandingPageData, getLandingTopBarData } = this.props;
      const { isMobile } = this.state;
      resetComments();
      resetLandingPage();
      getLandingPageData(token, isMobile ? 'mobile' : 'web');
      getLandingTopBarData(token);
      window.scrollTo(0, 0);
    }
  }

  _handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const {
      user: {
        user: { profile },
      },
      explore,
      topBarData,
      showDonationModal: { type },
      showInvitationModal: { isVisible, data: { model, invitation_id } }
    } = this.props;
    const {
      isMobile,
      search,
      isExplorePage,
      showMobileNavMenu,
      businessDefaultLink,
      showProfileMenu,
      showNotificationBar,
      currentPage,
    } = this.state;
    const titleOfSearchBar =
      explore &&
        explore.data &&
        explore.data.length &&
        explore.data.filter((_) => _ && _.ui_type && _.ui_type === 1).length
        ? explore.data.filter((_) => _ && _.ui_type && _.ui_type === 1)[0].title
        : 'Search people, collaborations...';

    return (
      <div className="top-bar">
        <div className="top-bar__content">
          <div className="top-bar__content__left">
            {isMobile && (
              <button className="top-bar__hamburger" onClick={this._toggleMobileNavMenu}>
                <img alt="menu" src={HamburgerMenuIcon} />
              </button>
            )}
            <Link
              onClick={this._getHomeIfHomePage}
              className="top-bar__content__logo__container"
              to={homeLink}
            >
              <img className="top-bar__content__logo" src={Logo} alt="logo" />
            </Link>
            {!isMobile && !isExplorePage && (
              <Link to="/explore">
                <input
                  className="top-bar__content__search"
                  type="text"
                  value={search}
                  name="search"
                  onChange={this._handleInputChange}
                  placeholder={titleOfSearchBar}
                />
              </Link>
            )}
          </div>
          <div className="top-bar__content__right">
            {!isMobile && (
              <div className={`top-bar__content__quick-links__container mobile-hidden`}>
                <NavLink
                  className={`top-bar__content__quick-links tab-hidden home-link ${currentPage === '/home' && showNotificationBar
                    ? 'top-bar__content__quick-links__overlay'
                    : ''
                    }`}
                  to={homeLink}
                >
                  Home
                </NavLink>
                <NavLink
                  className={`top-bar__content__quick-links ${currentPage === '/feed' && showNotificationBar
                    ? 'top-bar__content__quick-links__overlay'
                    : ''
                    }`}
                  id="feed-link"
                  to={feedLink}
                >
                  Feed
                </NavLink>
                {!!profile && !!profile.is_ttfb_user && businessDefaultLink && (
                  <NavLink
                    exact
                    className={`top-bar__content__quick-links  ${currentPage === businessDefaultLink && showNotificationBar
                      ? 'top-bar__content__quick-links__overlay'
                      : 'top-bar__content__quick-links--business'
                      }`}
                    id="business-link"
                    to={businessDefaultLink}
                  >
                    Business
                  </NavLink>
                )}
                <NavLink
                  className="top-bar__content__quick-links"
                  activeClassName="top-bar__content__quick-link active"
                  id="services-link"
                  to={miscLinks.services}
                >
                  Services
                </NavLink>
                <NavLink
                  className="top-bar__content__quick-links"
                  activeClassName="top-bar__content__quick-link active"
                  id="about-link"
                  to={miscLinks.about}
                >
                  About
                </NavLink>
              </div>
            )}
            <div className="top-bar__content__user-links__container">
              <TopBar
                topBarData={topBarData.data.top_bar}
                profile={profile}
                isMobile={isMobile}
                handleNotificationClick={this._handleNotificationClick}
                showNotificationBar={showNotificationBar}
                currentPage={currentPage}
              />
              {profile ? (
                <div className="top-bar__popup__container" id="profile-link">
                  <button className="top-bar__content__btn" onClick={this._handleProfileIconClick}>
                    <div className="top-bar__content__user-links--link__profile">
                      <div className="top-bar__content__user-links--link">
                        <Avatar
                          className="top-bar__content__user-links--link"
                          src={
                            profile.image_meta ? JSON.parse(profile.image_meta).original_photo : ''
                          }
                          alt="user"
                        />
                      </div>
                      <img
                        src={ProfileDownIcon}
                        className="top-bar__content__user-links--link__profile__chevron"
                        alt="dropdown"
                      />
                    </div>
                  </button>
                  {showProfileMenu && <ProfilePopUp isMobile={isMobile} handleDonationModal={this.handleDonationModal} />}
                </div>
              ) : (
                <div className="top-bar__popup__container">
                  <button className="top-bar__content__btn" onClick={this._handleProfileIconClick}>
                    <div className="top-bar__content__user-links--link"></div>
                  </button>
                  {showProfileMenu && <ProfilePopUp isLoggedOut={true} isMobile={isMobile} />}
                </div>
              )}
              {isMobile && (
                <div className={` full-screen-modal transiton_effect ${this.state.active === true ? "active" : ""}`}>
                  <div className="full-screen-modal__close">
                    <button
                      onClick={this._toggleMobileNavMenu}
                      className="full-screen-modal__close__btn"
                    >
                      <img
                        className="full-screen-modal__close__btn"
                        src={CloseIcon}
                        alt="close modal"
                      />
                    </button>
                  </div>
                  <div className="top-bar__popup__navmodal">
                    <NavLink
                      onClick={this._removeOverflowClass}
                      className="top-bar__popup__navmodal__link"
                      to={homeLink}
                      id="mobile-menu-home"
                    >
                      Home
                    </NavLink>
                    <NavLink
                      onClick={this._removeOverflowClass}
                      className="top-bar__popup__navmodal__link"
                      to={exploreLink}
                      id="mobile-menu-explore"
                    >
                      Search
                    </NavLink>
                    <NavLink
                      onClick={this._removeOverflowClass}
                      className="top-bar__popup__navmodal__link"
                      to={feedLink}
                      id="mobile-menu-feed"
                    >
                      Feed
                    </NavLink>
                     <NavLink
                      onClick={this._removeOverflowClass}
                      className="top-bar__popup__navmodal__link"
                      to={miscLinks.services}
                      id="mobile-menu-feed"
                    >
                      Services
                    </NavLink> 
                   <NavLink
                      onClick={this._removeOverflowClass}
                      className="top-bar__popup__navmodal__link"
                      to={miscLinks.about}
                      id="mobile-menu-feed"
                    >
                      About
                    </NavLink> 
                  </div>
                  <div className="top-bar__popup__navfooter">
                    {/* <a
                      href="https://blog.tagtaste.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="top-bar__popup__navfooter__link"
                    >
                      Blogs
                    </a> */}
                    <Link to="/career" className="top-bar__popup__navfooter__link">
                      Join us
                    </Link>
                    <Link to="/terms" className="top-bar__popup__navfooter__link">
                      Terms &amp; Conditions
                    </Link>
                    <Link to="/privacy" className="top-bar__popup__navfooter__link">
                      Privacy Policy
                    </Link>

                    <p className="top-bar__popup__navfooter__copyright">
                      © TagTaste Foods Pvt. Ltd. | {new Date().getFullYear()}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ProfileCommonModals />
        <PassbookModal />
        {type == "profile" ? <DonationModal handleDonation={this.handleDonationModal} /> : null}
        <CommonPortal>
          <ToastContainer className="toast-container" />
          {
            model === "notification" && isVisible &&
            <ShowInvitationDetailsModal
              handleCloseInvitationModal={this.handleCloseInvitationModal} invitationId={invitation_id} notificationViewDetail={true} handleUpdateList={this.handleUpdateList} />
          }
        </CommonPortal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    profileModals: state.modalReducer.profileModals,
    token: state.login.token,
    explore: state.explore,
    topBarData: state.landingPageReducer.topBar,
    showDonationModal: state.modalReducer.collaboration.showDonationModal,
    showInvitationModal: state.modalReducer.collaboration.showInvitationModal,

  };
};

const mapDispatchToProps = {
  toggleProfileMenuModal,
  toggleProfileCompletionModal,
  resetFeed,
  getFeed,
  resetComments,
  getTTFBSidebar,
  setTTFBSidebarDropdown,
  resetLandingPage,
  getLandingPageData,
  getLandingTopBarData,
  setShowDonationModal,
  setHideDonationModal,
  saveDonationModalData,
  UpdateNotificationItem,
  hideInvitationDetailsModal
};

LoggedInBar.propTypes = {
  postToShare: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
  setTTFBSidebarDropdown: PropTypes.func,
  getTTFBSidebar: PropTypes.func,
  profileModals: PropTypes.object,
  toggleProfileMenuModal: PropTypes.func,
  location: PropTypes.object,
  resetComments: PropTypes.func,
  resetFeed: PropTypes.func,
  getFeed: PropTypes.func,
  explore: PropTypes.object,
  resetLandingPage: PropTypes.func,
  getLandingPageData: PropTypes.func,

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggedInBar));
