import { getPRStatusObject } from "./collaborationHelper";

export const SINGLE_SELECT_QUESTION_TYPE = 1;
export const MULTI_SELECT_QUESTION_TYPE = 2;
export const COMMENT_SELECT_QUESTION_TYPE = 3;
export const LINEAR_SELECT_QUESTION_TYPE = 5;
export const RANK_SELECT_QUESTION_TYPE = 7;
export const RANGE_SELECT_QUESTION_TYPE = 8;
export const MULTI_GRID_SINGLE_SELECT_QUESTION_TYPE = 9;

export const TASTING_TYPE = "tasting";

export const REVIEW_FILTER_TYPES = {
    QUESTION_FILTER: 'question_filter',
    CURRENT_STATUS: 'current_status',
    SENSORY_TRAINED: 'sensory_trained',
    DATE: 'date',
    SHOW_INTEREST_DATE: 'show_interest_date',
    REVIEW_DATE: 'review_date',
    HOMETOWN: 'hometown',
    CURRENT_CITY: 'current_city'
};

export const removePRFalseFilters = (data) => {
    let questionFilter = [];
    if (!data) return [];
    questionFilter = data.reduce((acc, section) => {
        const sectionQuestions = section.questions.map(question => {
            const filteredOptions = question.option.filter(option => option.is_selected);
            return { ...question, option: filteredOptions };
        });

        const filteredQuestions = sectionQuestions.filter(question => question.is_selected);
        if (filteredQuestions.length > 0) {
            const sectionData = { ...section, questions: filteredQuestions };
            return [...acc, sectionData];
        }
        return acc;
    }, []);

    return [...questionFilter]
};

export const getSelectedQuestionsCount = (data) => {
    if (!data) return false;
    let questionsArray = [];
    if (Array.isArray(data)) { // handling non section survey 
        questionsArray = data.reduce((acc, section) => {
            if (Array.isArray(section.questions)) {
                return acc.concat(section.questions);
            } else {
                return acc.concat(section);
            }
        }, []);
    } else if (Array.isArray(data.questions)) {// handling section / pr questions array
        questionsArray = data.questions;
    } else {
        return false; // If data is not an array and doesn't contain a questions array, return false
    }
    const count = questionsArray.filter(question => question.is_selected).length;
    return count;
}

export const prefrenceData = (profile) => {
    const data = {
        donate: profile.is_donation === null ? "" : !!profile.is_donation ? "donate" : "self",
        preference: profile.is_donation === null ? false : true,
        terms: true,
        organization: profile.donation_organisation || {}
    }
    return data
}

//function to check if all values of object are empty or not

export function isEmptyData(obj) {
    const demographyObj = {...obj}
    delete demographyObj["other_active"]
    return Object.values(demographyObj).every(val => {
        if (typeof val === 'object' && val !== null) {
            return isEmptyData(val);
        }
        return val === 0;
    });
}
export const defaultDemographyData = {
    other_active: 0,
    gen_s: {
        male: 0,
        female: 0,
        other: 0,
    },
    gen_x: {
        male: 0,
        female: 0,
        other: 0
    },
    millennials: {
        male: 0,
        female: 0,
        other: 0
    },
    gen_z: {
        male: 0,
        female: 0,
        other: 0
    },
    gen_a: {
        male: 0,
        female: 0,
        other: 0
    }
}
const citydata = (cityList) => {
    let cityIds = cityList && cityList.length && cityList.map((val, index) => parseInt(val.id));

    let emptyCityData = cityIds && cityIds.map((id) => {
        return {
            [id]: defaultDemographyData
        }
    })
    const mergedObject = emptyCityData.reduce((acc, cur) => {
        return Object.assign(acc, cur);
    }, {});
    return mergedObject;
}
export const getDemogrpahyEmptyData = (isCity, cityList) => {
    return !!isCity ? { ...citydata(cityList) } : defaultDemographyData;
}


// status & text for PR Manage section pills 

export const statusOfApplicants = [
    { type: "applicants", title: "Active" },
    {
        type: "rejected",
        title: "Rejected",
    },
    {
        type: "invited",
        title: "Invited",
    },
];

export const statusOfProductApplicants = [
    {
        type: "",
        title: "All Applicants",
        urlTitle: "",
    },
    {
        type: "0",
        title: getPRStatusObject(0).title,
    },
    {
        type: "1",
        title: getPRStatusObject(1).title,
    },
    {
        type: "2",
        title: getPRStatusObject(2).title,
    },
    {
        type: "3",
        title: getPRStatusObject(3).title,
    },
];

// helper function to get final submit demography payload 
// ensures in each generation other is set to 0 if other_active is false while submitting
export const getDemographyPayload = (product, productIndex, addresses, availableCities, isCityWiseChecked) => {
    //City-wise processing
    if (isCityWiseChecked) {
        const cityIDs = addresses.map((address) => parseInt(address.id));

        const demographySet = cityIDs.map((cityID) => {
            if (availableCities[productIndex].includes(cityID.toString())) {
                const cityDemography = product.demography[cityID];

                const updatedCityDemography = Object.entries(cityDemography).reduce((acc, [generationKey, generationValue]) => {
                    if (generationKey === "other_active") {
                        acc[generationKey] = Number(generationValue);
                        return acc;
                    }
                    acc[generationKey] = {
                        ...generationValue,
                        other: cityDemography.other_active ? generationValue.other : 0, // Update `other` based on `other_active`
                    };
                    return acc;
                }, {});

                return { [cityID]: updatedCityDemography };
            }
            return null;
        }).filter(Boolean);

        return demographySet.reduce((acc, cur) => ({ ...acc, ...cur }), {});
    } else {
        // Non-city-wise processing
        return Object.entries(product.demography).reduce((acc, [generationKey, generationValue]) => {
            if (generationKey === "other_active") {
                acc[generationKey] = Number(generationValue);
                return acc;
            }
            acc[generationKey] = {
                ...generationValue,
                other: product.demography.other_active ? generationValue.other : 0, // Update `other` based on `other_active`
            };

            return acc;
        }, {});
    }
};
// checking if mandatory rank question is there and all its ranks are selected
export function checkRankMandatory(currentAnswerKeys, currentAnswers, questionId) {
    if (!currentAnswerKeys.includes(questionId)) return true
    return currentAnswers[questionId].option.some((eachOption) => eachOption == "");
};
